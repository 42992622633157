import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist';

import actions from './actions'
import getters from './getters.coffee'
import mutations from './mutations.coffee'
import es6Mutations from './es6Mutations'

import { nodeApi } from '../config'

Vue.use(Vuex)

// console.log('nodeApi is ', nodeApi)

const vuexLocalStorage = new VuexPersist({
  key: 'teachee-v1.0.2', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  reducer: (state) => {
    return ({
      voiceGender: state.voiceGender,
      activities: state.activities,
      visitedLessons: state.visitedLessons,
      lastLessons: state.lastLessons,
      students: state.students,
      user: state.user,
      defaultHours: state.defaultHours,
      purchases: state.purchases,
      voices: state.voices,
      allLessons: state.allLessons,
      usedLanguages: state.usedLanguages,
      compactMode: state.compactMode,
      navCompactMode: state.navCompactMode,
      showShortIpaVersion: state.showShortIpaVersion,
    })
  }
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export const store = new Vuex.Store({
  state: {
    isConnected: false,
    isEditingSentenceContent: false,
    compactMode: false,
    navCompactMode: false,
    apiUrl: nodeApi,
    uuid: null,
    voiceGender: 'male',
    layout: 'no-layout',
    layoutBackground: '',
    student: {},
    students: [],
    purchases: [],
    usedLanguages: [],
    purchase: {},
    lastActivity: new Date(),
    showShortIpaVersion: true,
    user: {},
    storageStats: 0,
    pack: {
      user: {}
    },
    packs: [],
    lesson: {},
    lessons: [],
    activities: [],
    visitedLessons: [],
    lastLessons: [],
    voices: {
      'en-US': "Samantha",
      'en-GB': "Daniel",
      'de-DE': "Anna",
      'hu-HU': "Mariska",
      'fr-FR': 'Thomas',
      'it-IT': 'Google italiano',
      'pt-BR': 'Google português do Brasil',
      'zh-CN': 'Ting-Ting',
      'ar-SA': 'Maged',
      'es-ES': 'Jorge',
      'ko-KR': 'Yuna',
      "ru-RU": "Milena",
      "cs-CZ": "Zuzana",
      "he-IL": "Carmit",
      "hi-IN": "Lekha",
      "ja-JP": "Kyoko",
      "id-ID": "Damayanti",
      "nb-NO": "Nora",
      "pl-PL": "Zosia",
      "ro-RO": "Ioana",
      "sk-SK": "Laura",
      "th-TH": "Kanya",
      "da-DK": "Sara",
      "el-GR": "Melina",
      "fi-FI": "Satu",
    },
    sentences: [{}],
    sentencesOptions: [],
    categories: [],
    allLessons: [],
    usersById: {},
    lastAudio: {},
    defaultHours: [8, 9, 10, 11, 13, 14, 15, 16],
  },
  plugins: [vuexLocalStorage.plugin],
  actions,
  getters,
  mutations: Object.assign({}, mutations, es6Mutations)
})
