<template lang="pug">
  .container.subscribe-page
    .box.is-narrow
      .columns
        .column.is-12
          section.section.pt-3
            h2.title.has-text-left Pricing
            pricing-table(@plan-chosen="planChosen" :can-buy="true" highlight="premium_monthly")

</template>

<script>
import pricingTable from "@/components/PricingTableV2";
import offers from "@/support/offers";
import paymentModal from "@/components/PaymentModal";
import {mapGetters} from 'vuex'

const CREDITS_PRODUCT_IDS = offers.filter(offer => offer.kind === "credit" && offer.product_id).map(o => o.product_id)

export default {
  components: {
    pricingTable,
    paymentModal
  },

  data() {
    return {
      card: null,
      isPaying: null,
      error: null,
      selectedPlan: null,
      kind: null,
      complete: false,
      plans: [
        {
          id: "basic_monthly",
          name: "Basic (19.90€/Mo)"
        },
        {
          id: "basic_yearly",
          name: "Basic (199.00€/Year) • 2 months for free"
        },
        {
          id: "premium_monthly",
          name: "Standard (49.90€/Mo)"
        },
        {
          id: "premium_yearly",
          name: "Standard (499.00€/Year) • 2 months for free"
        }
      ]
    };
    // see https://stripe.com/docs/stripe.js#element-options for details
  },
  computed: mapGetters(['user']),
  mounted() {
    this.loadScript("https://cdn.paddle.com/paddle/paddle.js", () => {
      // HANDLE SPECIAL HIDDEN OFFER
      const {plan} = this.$route.query
      if (plan) {
        this.kind = 'plan'
        this.selectedPlan = plan
      }

      console.log({paddle: window.Paddle})
      //
      // if (window.Paddle) {
      //   const toast = this.$toast;
      //   const analytics = this.$analytics;
      //   const auth = this.$auth;
      //   const store = this.$store;
      //   const ga = this.$ga;
      //   const user = this.user;
      //
      //   window.Paddle.Setup({
      //     vendor: 51620,
      //     eventCallback: function(data) {
      //       if (data.event === 'Checkout.PaymentComplete') {
      //         if (analytics && analytics.fbq && analytics.fbq.event) {
      //           analytics.fbq.event("Subscribe")
      //         }
      //         if (window.rdt) {
      //           window.rdt('track', 'Purchase');
      //         }
      //         toast.success("The payment was successfully processed", { duration: 5000 })
      //         if (CREDITS_PRODUCT_IDS.includes(data.product)) {
      //           ga.event("conversion", "payment", "credits");
      //         } else if (user.paid_credits === true) {
      //           ga.event("conversion", "payment", "subscription_after_credits");
      //         } else {
      //           ga.event("conversion", "payment", "subscription");
      //         }
      //
      //         setTimeout(() => {
      //           auth.fetch({
      //             success: (data) => {
      //               store.dispatch("setUser", data.body.data);
      //             },
      //           });
      //         }, 2000)
      //       }
      //     }
      //   });
      // }
    })
  },
  methods: {
    loadScript(url, callback) {
      if (!document.querySelector(`script[src="${url}"]`)) {
        const script = document.createElement("script")
        script.type = "text/javascript";

        if (script.readyState) {  //IE
          script.onreadystatechange = function () {
            if (script.readyState == "loaded" ||
              script.readyState == "complete") {
              script.onreadystatechange = null;
              if (callback) callback();
            }
          };
        } else {  //Others
          script.onload = function () {
            if (callback) callback();
          };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      } else {
        if (callback) callback();
      }
    },
    onPaymentSuccess(data) {
      const toast = this.$toast;
      const analytics = this.$analytics;
      const auth = this.$auth;
      const store = this.$store;
      const ga = this.$ga;
      const user = this.user;
      console.log({ data })

      if (analytics && analytics.fbq && analytics.fbq.event) {
        analytics.fbq.event("Subscribe")
      }
      if (window.rdt) {
        window.rdt('track', 'Purchase');
      }
      toast.success("The payment was successfully processed", { duration: 5000 })
      if (data.product && data.product.id && CREDITS_PRODUCT_IDS.includes(data.product.id)) {
        ga.event("conversion", "payment", "credits");
      } else if (user.paid_credits === true) {
        ga.event("conversion", "payment", "subscription_after_credits");
      } else {
        ga.event("conversion", "payment", "subscription");
      }

      setTimeout(() => {
        auth.fetch().then( (data) => {
            console.log({userFetched: data})
            store.dispatch("setUser", data.body.data);
          })
      }, 2500)
    },
    planChosen(plan) {
      console.log(plan)
      this.kind = 'plan'
      this.selectedPlan = plan.replace(/monthly/, 'yearly');
      this.$mixpanel.track("Subscribe - chose - "+plan);
      this.selectedPlan = offers.find(o => o.uid === plan).product_id;
      switch (plan) {
        case "credit_7":
        case "credit_27":
        case "credit_47":
          this.kind = 'pass';
          break;
        default:
        // case "free":
        //   this.selectedPlan = null;
        //   break;
        // case "basic":
        //   this.selectedPlan = "basic_yearly";
        //   break;
        // case "standard":
        //   this.selectedPlan = "standard_yearly";
        //   break;
        // case "standard_limited":
        //   this.selectedPlan = "standard_limited";
        //   this.kind = 'pass'
        //   break;
      }

      window.Paddle.Checkout.open({
        product: this.selectedPlan,
        email: this.user.email,
        customer_country: this.user.country,
        allowQuantity: false,
        passthrough: `{"user_id": ${this.user.id}}`,
        customData: {
          tolt_referral: window.tolt_referral,
        },
        successCallback: this.onPaymentSuccess
      });

      // window.scrollTo({
      //   'behavior': 'smooth',
      //   'left': 0,
      //   'top': document.getElementById('subscribe_section').getBoundingClientRect().top+320
      // });
    }
  }
};
</script>

<style lang="scss">
@import "colors";

.subscribe-page {

  .pricing-table .pricing-plan .plan-header {
    padding-bottom: 0;
    background-color: transparent;
  }

  .pricing-table .pricing-plan .plan-item {
    background-color: transparent;
  }

  .pricing-plan:first-child {
    /*display: none*/
    button.button {
      display: none;
    }
  }
  .pricing-plan:nth-child(2) {
    max-width: 40%;
    /*margin-left: 17%;*/
  }
  .pricing-plan:nth-child(3) {
    /*margin-right: 17%;*/
  }
  .pricing-table .pricing-plan.is-active {
  }
  /*.pricing-plan:not(.is-active) {*/
    /*display: none;*/
  /*}*/
  /*.pricing-plan {*/
    /*.plan-footer,*/
    /*button {*/
      /*display: none !important;*/
    /*}*/
  /*}*/

  .pricing-table.selected {
    .pricing-plan:not(.selected) {
      opacity: 0.5;
    }
  }
}

.stripe-card {
  width: 300px;
  border: 1px solid grey;
}

.stripe-card.complete {
  border-color: green;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
</style>
