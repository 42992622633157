import Vue from "vue";
import Router from "vue-router";
import RouterPrefetch from 'vue-router-prefetch'
import {store} from '../store/store'

// import FrontPage from "@/components/FrontPageV2";
import FrontPage from "@/components/v3/Homepage";
// const AffiliateProgramPage = () => import("@/components/pages/AffiliateProgramPage");
// //const Availabilities = () => import("@/components/Availabilities");
// //const Bookings = () => import("@/components/Bookings");
// const CorrectionsPage = () => import("@/components/CorrectionsPage");
// const ForStudentsPage = () => import("@/components/v3/ForStudents");
// const ForTeachersPage = () => import("@/components/v3/ForTeachers");
// const PressKitPage = () => import("@/components/v3/PressKit");
// const Home = () => import("@/components/Home");
// const LessonsPage = () => import("@/components/pages/LessonsPage");
// const PeoplePage = () => import("@/components/pages/PeoplePage");
// const LessonsAutoCreatePage = () => import("@/components/pages/LessonsAutoCreatePage");
// const LiveLesson = () => import("@/components/LiveLesson");
// const LostPasswordPage = () => import("@/components/LostPasswordPage");
// const MyAccount = () => import("@/components/MyAccount");
// const NewPackPage = () => import("@/components/NewPackPage");
// const DuplicateLessonPage = () => import("@/components/DuplicateLesson");
// const NewSmartLessonPage = () => import("@/components/NewSmartLessonPage");
// const NpsPage = () => import("@/components/NpsPage");
// const PacksPage = () => import("@/components/PacksPage");
// const PricingPage = () => import("@/components/v3/Pricing");
// const PrivacyPage = () => import("@/components/PrivacyPage");
// const ReadOnlyLessons = () => import("@/components/ReadOnlyLessons");
// const RefreshCache = () => import("@/components/RefreshCache");
// const ResetPasswordPage = () => import("@/components/ResetPasswordPage");
// const SharedLessonsPage = () => import("@/components/SharedLessonsPage");
// const ShowPackPage = () => import("@/components/ShowPackPage");
// const SignIn = () => import("@/components/SignIn");
// const SignUp = () => import("@/components/SignUp");
// const StudentAllSentencesPage= () => import("@/components/StudentAllSentencesPage");
// const StudentPage = () => import("@/components/StudentPage");
// const Students = () => import("@/components/StudentsV2");
// const SubscribePage = () => import("@/components/SubscribePage");
// const TeacherPage = () => import("@/components/TeacherPage");
// const ToolsPage = () => import("@/components/ToolsPage");
// const PronunciationPage = () => import("@/components/v3/Pronunciation");
// const UsersPage = () => import("@/components/UsersPage");
// const AdminAllSentencesPage = () => import("@/components/AdminAllSentencesPage");
// const VisitedLessons = () => import("@/components/VisitedLessons");
// import TosPage = () => import("@/components/TosPage";

import AffiliateProgramPage from "@/components/pages/AffiliateProgramPage";
import CorrectionsPage from "@/components/CorrectionsPage";
import ForStudentsPage from "@/components/v3/ForStudents";
import ForTeachersPage from "@/components/v3/ForTeachers";
import PressKitPage from "@/components/v3/PressKit";
import Home from "@/components/Home";
import LessonsPage from "@/components/pages/LessonsPage";
import PeoplePage from "@/components/pages/PeoplePage";
import LessonsAutoCreatePage from "@/components/pages/LessonsAutoCreatePage";
import LiveLesson from "@/components/LiveLesson";
import LostPasswordPage from "@/components/LostPasswordPage";
import MyAccount from "@/components/MyAccount";
import NewPackPage from "@/components/NewPackPage";
import DuplicateLessonPage from "@/components/DuplicateLesson";
import NewSmartLessonPage from "@/components/NewSmartLessonPage";
import NpsPage from "@/components/NpsPage";
import PacksPage from "@/components/PacksPage";
import PricingPage from "@/components/v3/Pricing";
import PrivacyPage from "@/components/PrivacyPage";
import ReadOnlyLessons from "@/components/ReadOnlyLessons";
import RefreshCache from "@/components/RefreshCache";
import ResetPasswordPage from "@/components/ResetPasswordPage";
import SharedLessonsPage from "@/components/SharedLessonsPage";
import ShowPackPage from "@/components/ShowPackPage";
import SignIn from "@/components/SignIn";
import SignUp from "@/components/SignUp";
import StudentAllSentencesPage from "@/components/StudentAllSentencesPage";
import StudentPage from "@/components/StudentPage";
import Students from "@/components/StudentsV2";
import SubscribePage from "@/components/SubscribePage";
import TeacherPage from "@/components/TeacherPage";
import ToolsPage from "@/components/ToolsPage";
import PronunciationPage from "@/components/v3/Pronunciation";
import UsersPage from "@/components/UsersPage";
import AdminAllSentencesPage from "@/components/AdminAllSentencesPage";
import VisitedLessons from "@/components/VisitedLessons";


Vue.use(Router);
Vue.use(RouterPrefetch)

const router =  new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "FrontPage",
      component: FrontPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/for-teachers",
      name: "ForTeachersPage",
      component: ForTeachersPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/press-kit",
      name: "PressKitPage",
      component: PressKitPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/pronunciation",
      name: "PronunciationPage",
      component: PronunciationPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/pronunciation_no_ipa",
      name: "PronunciationPage",
      component: PronunciationPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/affiliate",
      name: "AffiliateProgramPage",
      component: AffiliateProgramPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/for-students",
      name: "ForStudentsPage",
      component: ForStudentsPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: PricingPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/lessons/new",
      name: "LessonsAutoCreatePage",
      component: LessonsAutoCreatePage,
    },
    {
      path: "/lessons/:id",
      name: "LiveLesson",
      component: LiveLesson,
      meta: {
        guest: true
      }
    },
    {
      path: "/lessons/:id/duplicate",
      name: "DuplicateLesson",
      component: DuplicateLessonPage,
    },
    {
      path: "/home",
      name: "Home",
      component: Home
    },
    {
      path: "/lessons",
      name: "LessonsPage",
      component: LessonsPage
    },
    {
      path: "/lessons/lg/:lang",
      name: "LessonsByLanguagePage",
      component: LessonsPage
    },
    {
      path: "/teachers/:id",
      name: "TeacherPage",
      component: TeacherPage
    },
    {
      path: "/students/:id",
      name: "StudentPage",
      component: StudentPage
    },
    {
      path: "/users",
      name: "UsersPage",
      component: UsersPage
    },
    {
      path: "/admin_sentences",
      name: "AdminAllSentencesPage",
      component: AdminAllSentencesPage
    },
    {
      path: "/contacts",
      name: "People",
      component: PeoplePage
    },
    {
      path: "/students",
      name: "Students",
      component: Students
    },
    {
      path: "/past-lessons",
      name: "VisitedLessons",
      component: VisitedLessons
    },
    {
      path: "/profile",
      name: "MyAccount",
      component: MyAccount
    },
    {
      path: "/sign_up",
      name: "SignUp",
      component: SignUp,
      meta: {
        guest: true
      }
    },
    {
      path: "/sign_in",
      name: "SignIn",
      component: SignIn,
      meta: {
        guest: true
      }
    },
    {
      path: "/refresh_cache",
      name: "RefreshCache",
      component: RefreshCache,
      meta: {
        guest: true
      }
    },
    {
      path: "/subscribe",
      name: "SubscribePage",
      component: SubscribePage
    },
    {
      path: "/lesson-builder",
      name: "NewSmartLessonPage",
      component: NewSmartLessonPage
    },
    {
      path: "/corrections",
      name: "CorrectionsPage",
      component: CorrectionsPage
    },
    {
      path: "/lost_password",
      name: "LostPasswordPage",
      component: LostPasswordPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/reset_password/:token",
      name: "ResetPasswordPage",
      component: ResetPasswordPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/tools",
      name: "ToolsPage",
      component: ToolsPage
    },
    {
      path: "/packages/:id",
      name: "ShowPackPage",
      component: ShowPackPage
    },
    {
      path: "/community",
      name: "SharedLessonsPage",
      component: SharedLessonsPage
    },
    {
      path: "/packages",
      name: "PacksPage",
      component: PacksPage
    },
    {
      path: "/packages/new",
      name: "NewPackPage",
      component: NewPackPage
    },
    {
      path: "/packages/:id/edit",
      name: "EditPackPage",
      component: NewPackPage
    },
    {
      path: "/privacy",
      name: "PrivacyPage",
      component: PrivacyPage,
      meta: {
        guest: true
      }
    },
    {
      path: "/nps",
      name: "NpsPage",
      component: NpsPage
    },
    // {
    //   path: "/terms-of-service",
    //   name: "TosPage",
    //   component: TosPage
    // },

    {
      path: "/teachers/:teacher_id/lessons",
      name: "allForTeacher",
      component: ReadOnlyLessons
    },
    {
      path: "/students/:student_id/lessons",
      name: "allForStudent",
      component: ReadOnlyLessons
    },
    {
      path: "/students/:student_id/sentences",
      name: "allSentencesForStudent",
      component: StudentAllSentencesPage
    },
    {
      path: "/p/:lesson_uuid",
      name: "ReadOnlyPackageLesson",
      component: ReadOnlyLessons
    },
    // {
    //   path: "/availabilities",
    //   name: "Availabilities",
    //   component: Availabilities
    // },
    // {
    //   path: "/bookings",
    //   name: "Bookings",
    //   component: Bookings
    // }
  ]
});

router.beforeEach((to, from, next) => {
  // console.log({ to, from, user: store.getters.user })
 if (to.matched.some(record => record.meta.guest)) {
   next()
 } else {
   const user = store.getters.user

   if (user.id) {
     next()
   } else {
     Vue.localStorage.set("redirectAfterLogin", to.path);
     Vue.localStorage.set("redirectQueryAfterLogin", JSON.stringify(to.query));

     if (Vue.cookie.get('gdprConsented')) {
       next({ name: 'SignIn'})
     } else {
       next({ name: 'SignUp'})
     }
   }
 }

})

export default router
