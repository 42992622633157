<template lang="pug">
  .container
    div.has-text-right.mb-3(
      v-if="$route.query.export"
    )
      button.button.is-info.px-3(
      @click="exportAllLessons"
      )
        b-icon.mr-2(icon="cloud-download")
        | Export all my lessons

    alert.mb-4(
        v-if="user.from_partner === 'tom'"
        uid="welcome_from_tom"
        color='is-info'
      )
      h2.title.mb-1 Welcome to Teachee
      div
        | Tom's perk as been applied, Teachee is giving you <b>1 full month of free pro subscription</b> 🎉

    alert.mb-4(
      v-if="user.role === 'free' && !user.from_partner"
      uid="welcome"
      color='is-info'
    )
      h2.title.mb-1 Welcome to Teachee
      div(v-if="!user.claimed_credits")
        | As a welcome gift, we give you <b>10 credits for free</b> 🎉
        b-tooltip(
          label='A credit gives you one lesson containing up to 50 sentences with media (record audio & add pictures).'
          type="is-light"
          multilined
          :animated='true'
          position='is-top')
          b-icon(icon="info" size="is-small")
        | . Claim your credits anytime by clicking here:
        button.button.is-white.is-small.is-outlined.ml-2(@click="getCredits" :class="{'is-loading': claiming}" :disabled="claiming") Get my 10 free credits
      div(v-else)
        | Well done! Now you can create lesson and record audio.<br>People you invite to the lesson will also be able to add audio and pictures.

    alert.mb-4(
      v-if="user.role === 'free' || user.from_partner"
      uid="learn_how_to_use"
      color='is-info'
    )
      h3.title.mb-1.is-size-6 How to use Teachee?
      .content
        ul.ul
          li
            p
              | Learn on <a href="https://blog.teachee.io/tag/feature/" target="_blank">our blog</a> which features are available to you.
          li
            p
              | Watch our <b>quick overview of the app</b> to get you started in minutes:
              img.quick-overview-img.is-clickable(src="@/assets/quick_overview.jpg" alt="Teachee | Quick Overview" @click="showQuickOverview" width="200")

    alert.mb-4(
        v-if="!publicLessons.length && lessons.length > 10"
        uid="public_lesson"
        color='is-info'
      )
        h2.title Gain exposure by creating a public lesson
        p Creating a lesson that any student can access to can help you grow your student base. We created a tool to help you just do that:
        router-link.button.px-3.mt-2.is-white.is-outlined(:to="{name: 'NewSmartLessonPage'}"
        )
          b-icon.mr-2(icon="build")
          | Lesson Builder

    .columns.is-multiline
      .column.is-4.d-none-empty
        activity-box
      .column.is-8.d-none-empty
        people-box
      .column.is-12(v-if="user.counter && user.counter.data && !isEmpty(user.counter.data)")
        progress-box
      .column.is-12(
      )
        .box.page-home.has-shadow

          section.my-lessons.pt-0
            .columns
              .column
                h3.title.has-text-left
                  | Lessons
                  span(
                    v-if="lessons.length && (lessons[0].id)"
                  )  not shared
              .column.text-align-right.is-narrow
                button#btn_add_lesson_head.button.is-outlined.is-small(@click="addLesson('en-US')")
                  b-icon(icon="add")
                  span Add a lesson
            p.subtitle.has-text-grey

            // EXPLANATIONS
            .section.pt-0(v-if="lessons.length === 0 || (lessons.length == 1 && !lessons[0].id)")
              article.message.is-light.has-text-left
                .message-body
                  | To add a lesson :
                  ol
                    li Click on "<b>+ Add</b>" or "+ Add a lesson"
                    li <b>Enter a title</b>, for instance : "Nov 12 - Lesson w/ George"
                    li Press <b>ENTER</b>
                    li
                      | <b>Share the lesson</b> to your student by clicking this button :&nbsp;
                      b-icon(icon="share")

            .section.pt-0
              lessons-list(
                size="is-3"
                :lessons="lessons"
                if-is-empty="visitors"
                group-by="language_to")

</template>


<script>
import people from "@/components/boxes/People";
import payments from "@/components/boxes/Payments";
import bookings from "@/components/boxes/Bookings";
import progress from "@/components/boxes/Progress";
import activity from "@/components/boxes/Activity";
import { freeStudentLimit } from "@/config";
import { mapGetters } from "vuex";
import alert from "@/components/Alert";
import lessonsList from "@/components/LessonsList";
import visitedLessons from "@/components/VisitedLessons";
import {Lesson} from '@/store/models/resources';
import isEmpty from "lodash/isEmpty";
import QuickSetupModal from "@/components/QuickSetupModal";

export default {
  name: "Home",
  components: {
    lessonsList,
    visitedLessons,
    alert,
    peopleBox: people,
    paymentsBox: payments,
    bookingsBox: bookings,
    progressBox: progress,
    activityBox: activity
  },
  data() {
    return {
      isEmpty,
      alert: true,
      claiming: false,
      user: {
        id: null,
        counter: {

        }
      },
      freeStudentLimit
    };
  },

  computed: {
    ...mapGetters(["lessons", 'apiUrl']),
    publicLessons() {
      return this.lessons.filter(lesson => lesson.is_public)
    },
  },
  created() {
    if (!this.$auth.check()) {
      this.$router.push("/sign_in");
    } else {
      // console.log('user: ', this.$auth.check())
      // console.log('user: ', this.$auth.user())
      this.user = this.$auth.user();
      this.$store.dispatch("setUser", this.user);
      this.$store.dispatch("getLessons", this.$auth.user());
      this.$store.dispatch("setLayout", "logged-in-layout");
      // this.importIpaDb()

      if (!this.user.teaches_languages.length && !this.user.learns_languages.length) {
        if (!window.welcomeModal) {
          window.welcomeModal = true
          if (!document.getElementById('modal_quick_setup')) {
            this.$buefy.modal.open({
              parent: this,
              width: 480,
              props: {
              },
              component: QuickSetupModal,
              hasModalCard: false,
              onCancel() {
                // console.log('has finisehd ipa edit')
              }
            })
          }
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.welcome_credits == 1) {
      if (!this.user.claimed_credits) {
        this.getCredits()
      } else {
        this.$toast.error("You already claimed your 15 welcome credits");
      }
    }
  },
  methods: {
    showQuickOverview() {
      this.$mixpanel.track("Home - Watch Quick Overview");
      this.$ga.event("conversion", "watch", "introduction_video");
      this.$buefy.modal.open({
        content: `<iframe width="853" height="479" src="https://www.youtube.com/embed/aMbc_8sf1AU?vq=hd720" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`,
        width: 853,
      })
    },
    addLesson(lang) {
      this.$store
        .dispatch("addLesson", {
          language_to: lang || "en-US",
          visitors: [],
          user_id: this.user.id,
          user: this.user
        })
        .then(() => {
          setTimeout(function() {
            document.querySelector(".lesson__title input").focus();
          }, 400);
        });
      this.$mixpanel.track("Home - Add lesson");
    },

    getCredits() {
      this.claiming = true
      this.$ga.event("conversion", "freemium", "claim_free_credits");
      this.$store
        .dispatch("claimCredits").then(data => {
        this.$store.dispatch("setUser", data.user);
        this.$auth.user(data.user);
        this.user = data.user

        this.claiming = false
        this.$toast.open({
          duration: 5000,
          message: 'You now have 10 credits',
          type: "success"
        });
      })
    },

    exportAllLessons() {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";

      Lesson.exportAll().then((response) => {
        const blob = new Blob(["\ufeff"+response.body], {type: "text/csv;charset=UTF-8"}),
          url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = 'all_lessons.csv';
        a.click();
        window.URL.revokeObjectURL(url);
      });
    }
  }
};
</script>


<style lang="scss" scoped>
.match-lesson {
  height: 85px;
}

.section:empty {
  display: none;
}


ol {
  margin: 4px 0 0 18px;
}

.column {
  position: relative;
}

.lesson__delete {
  position: absolute;
  top: -5px;
  right: -3px;
  display: none;
  height: 37px;
  min-height: 37px;
  line-height: 19px;
  border-radius: 50%;
  .mdi {
    font-size: 20px;
  }
}

.quick-overview-img {
  vertical-align: top;
  margin-left: 0.5rem;
  width: 180px;
}

.column:hover {
  .lesson__delete {
    display: block;
  }
}
</style>
