const items = [
  'Record audio',
  'Attach pictures',
  'Live edit',
  'Auto translate',
  'Export to flashcards',
  'Export all audio files',
  'Speech to text',
  'Voice recognition',
  //'Booking system',
  //'Payment system',
]

module.exports = [
  {
    public: true,
    kind: 'plan',
    name: 'Free',
    uid: 'free',
    price: 0,
    duration: '-',
    fee: 0.02,
    includes: [
      'Lessons without media',
      'Live edit',
      'Export to flashcards',
      // 'Export all audio files',
      // 'Speech to text',
      // 'Voice recognition',
      //'Booking system',
      //'Payment system',
    ]
  },
  {
    public: true,
    classes: '',
    kind: 'credit',
    name: 'Pay as you go',
    uid: 'credits',
    price: 0,
    prices: {
      '10': 7,
      '50': 27,
      '150': 47
    },
    uids: {
      '10': 'credit_7',
      '50': 'credit_27',
      '150': 'credit_47'
    },
    duration: '-',
    fee: 0,
    includes: items
  },
  {
    classes: '',
    kind: 'credit',
    name: 'Pay as you go',
    invoiceDescription: 'Gives 10 lessons with media',
    invoiceName: '10 Credits',
    credits: 10,
    uid: 'credit_7',
    sku: 'sku_credit_7',
    product_id: '563550',
    price: 7,
    duration: '-',
    fee: 0,
    includes: items
  },
  {
    classes: '',
    kind: 'credit',
    name: 'Pay as you go',
    invoiceDescription: 'Gives 50 lessons with media',
    invoiceName: '50 Credits',
    uid: 'credit_27',
    sku: 'sku_credit_27',
    product_id: '563547',
    credits: 50,
    price: 27,
    duration: '-',
    fee: 0,
    includes: items
  },
  {
    classes: '',
    kind: 'credit',
    name: 'Pay as you go',
    invoiceDescription: 'Gives 150 lessons with media',
    invoiceName: '150 Credits',
    credits: 150,
    uid: 'credit_47',
    sku: 'sku_credit_47',
    product_id: '563552',
    price: 47,
    duration: '-',
    fee: 0,
    includes: items
  },
  // {
  //   kind: 'pass',
  //   name: 'Early Adopter Pass<br>Limited Offer',
  //   invoiceDescription: 'Offers a 6-month access to the Standard plan',
  //   invoiceName: 'Early Adopter Pass',
  //   sku: 'sku_early_adopter_pass',
  //   classes: 'is-info is-promotion',
  //   uid: 'standard_limited',
  //   duration: '6 month',
  //   discountText: '84% OFF',
  //   fee: 0.02,
  //   price: 47,
  //   includes: [
  //     'Unlimited number of students',
  //     'Live edit',
  //     'Record audio',
  //     'Export to flashcards', 'Export all audio files',
  //     'Speech to text', 'Voice recognition',
  //     'Booking system',
  //     'Payment system'+
  //     '<br>2% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
  //   ]
  // },
  // {
  //   public: true,
  //   kind: 'plan',
  //   name: 'Basic',
  //   invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment',
  //   classes: 'is-warning',
  //   uid: 'basic_monthly',
  //   frequency: 'monthly',
  //   price: 12.90,
  //   cents: 90,
  //   fee: 0.06,
  //   includes: [
  //     'Up to 50 students',
  //     'Live edit',
  //     'Record audio',
  //     'Export to flashcards', 'Export all audio files',
  //     'Speech to text', 'Voice recognition',
  //     'Booking system',
  //     'Payment system'+
  //     '<br>6% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
  //   ]
  // },
  // {
  //   kind: 'plan',
  //   name: 'Basic',
  //   invoiceDescription: 'Yearly subscription.<br>2 months for free',
  //   classes: 'is-warning',
  //   uid: 'basic_yearly',
  //   frequency: 'yearly',
  //   price: 129.0,
  //   normalPrice: 154.80,
  //   cents: 90,
  //   fee: 0.06,
  //   includes: [
  //     'Up to 50 students',
  //     'Live edit',
  //     'Record audio',
  //     'Export to flashcards', 'Export all audio files',
  //     'Speech to text', 'Voice recognition',
  //     'Booking system',
  //     'Payment system'+
  //     '<br>6% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
  //   ]
  // },
  {
    public: true,
    kind: 'plan',
    name: 'Pro',
    invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment',
    classes: '',
    uid: 'premium_monthly',
    frequency: 'monthly',
    product_id: '563553',
    price: 14.90,
    cents: 90,
    fee: 0,
    includes: [
      'Unlimited lessons',
      'Record audio',
      'Attach pictures',
      //'<span class="has-text-danger">Sell packages&nbsp;</span>',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Speech to text',
      'Voice recognition',
      'Computer-generated audio',
      'Auto translate in bulk',
      //'Booking system',
      //'Payment system&nbsp;',
    ]
  },
  {
    public: true,
    kind: 'plan',
    name: 'Pro Yearly',
    discountText: '-35%',
    invoiceDescription: 'Yearly subscription.<br>35% OFF',
    classes: 'is-primary',
    uid: 'premium_yearly',
    frequency: 'yearly',
    product_id: '563554',
    price: 99.0,
    normalPrice: 154.80,
    cents: 0,
    fee: 0,
    includes: [
      'Unlimited lessons',
      'Record audio',
      'Attach pictures',
      'Live edit',
      'Auto translate',
      'Export to flashcards',
      'Export all audio files',
      'Speech to text',
      'Voice recognition',
      'Computer-generated audio',
      'Auto translate in bulk',
      //'Booking system',
      //'Payment system',
    ],
  },
  // {
  //   public: true,
  //   kind: 'plan',
  //   name: 'Pro+',
  //   invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment',
  //   classes: 'is-info',
  //   uid: 'proplus_monthly',
  //   frequency: 'monthly',
  //   product_id: '640266',
  //   price: 199.90,
  //   cents: 90,
  //   fee: 0,
  //   includes: [
  //     'Unlimited lessons',
  //     'Record audio',
  //     'Attach pictures',
  //     '<span class="has-text-info">Sell packages&nbsp;</span>',
  //     'Live edit',
  //     'Export to flashcards', 'Export all audio files',
  //     'Speech to text', 'Voice recognition',
  //     //'Booking system',
  //     //'Payment system&nbsp;',
  //   ]
  // },
  // {
  //   public: true,
  //   kind: 'plan',
  //   name: 'Pro+',
  //   discountText: '2 months free',
  //   invoiceDescription: 'Yearly subscription.<br>2 months for free',
  //   classes: 'is-info',
  //   uid: 'proplus_yearly',
  //   product_id: '640267',
  //   frequency: 'yearly',
  //   price: 1999.0,
  //   normalPrice: 2398.80,
  //   cents: 0,
  //   fee: 0,
  //   includes: [
  //     'Unlimited lessons',
  //     'Record audio',
  //     'Attach pictures',
  //     '<span class="has-text-info">Sell packages&nbsp;</span>',
  //     'Live edit',
  //     'Export to flashcards', 'Export all audio files',
  //     'Speech to text', 'Voice recognition',
  //     //'Booking system',
  //     //'Payment system',
  //   ]
  // },
  {
    kind: 'plan',
    name: 'Premium',
    invoiceDescription: 'Yearly subscription.<br>50% OFF',
    classes: 'is-success',
    uid: 'premium_limited_yearly',
    frequency: 'yearly',
    price: 149.0,
    normalPrice: 298.80,
    cents: 0,
    fee: 0,
    includes: [
      'Live edit',
      'Record audio & attach pictures (up to 2GB)',
      'Export to flashcards', 'Export all audio files',
      'Speech to text', 'Voice recognition',
      // 'Booking system',
      // 'Payment system'+
      // '<br>2% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
      // 'Sell packages'+
      // '<br>Keep 90% of the sales for yourself',
    ]
  },
  {
    kind: 'plan',
    name: 'Premium',
    invoiceDescription: 'Monthly subscription.<br>Cancelable at any moment<br>20% OFF',
    classes: 'is-danger',
    uid: 'premium_limited_monthly',
    frequency: 'monthly',
    price: 19.90,
    normalPrice: 24.90,
    cents: 90,
    fee: 0,
    includes: [
      'Live edit',
      'Record audio & attach pictures (up to 2GB/month)',
      'Export to flashcards', 'Export all audio files',
      'Speech to text', 'Voice recognition',
      // 'Booking system',
      // 'Payment system'+
      // '<br>2% Platform fee<br>+<br>Europe: 1.4% + €0.25 Stripe fee<br>US: 2.9%  + 30¢ Stripe fee',
      // 'Sell packages'+
      // '<br>Keep 90% of the sales for yourself',
    ]
  },
]


