<template lang="pug">
  aside.ui--sidebar-menu.menu.has-background-white.p-3.d-none-on-print(
    :class="{'compact-mode': navCompactMode}"
  )
    //- Lessons
    p.menu-label.valign.is-flex()
      span(v-if="!navCompactMode") General
      b-button.has-text-centered.ml-auto.is-hidden-mobile(
        :class="{'btn-is-compacted': navCompactMode}"
        @click="SET_NAV_COMPACT_MODE(!navCompactMode)"
        size="is-small"
      )
        b-icon(
          :icon="navCompactMode ? 'chevron-right' : 'chevron-left'"
        )
    ul.menu-list(
      :class="{'is-unclickable is-disabled': isGuest}"
    )
      li
        router-link(:to="{name: 'Home'}" @click.native="showNavBar = false" active-class="is-active" exact)
          //-b-icon.mr-1(icon="home")
          HomeIcon.mr-1
          | {{ navCompactMode ? '' : 'Home'}}

      li
        router-link(:to="{name: 'LessonsPage'}" @click.native="showNavBar = false" active-class="is-active" exact)
          //-b-icon.mr-1(icon="view-list")
          ClipboardListIcon.mr-1
          | {{ navCompactMode ? '' : 'Lessons'}}

        ul.menu-list.mt-0(v-if="usedLanguages.length > 1")
          li(v-for="lang in usedLanguages")
            router-link.is-size-7.flag-link(
              :to="{name: 'LessonsByLanguagePage', params: {lang: lang}}"
              @click.native="showNavBar = false"
              active-class="is-active"
              exact
              )
              i.flag(:class="'flag-'+lang")

      li
        router-link(:to="{name: 'People'}" @click.native="showNavBar = false" active-class="is-active" exact)
          //-b-icon.mr-1(icon="group")
          UserGroupIcon.mr-1
          | {{ navCompactMode ? '' : 'Contacts'}}

      li(v-if="user.role === 'proplus'")
        router-link(:to="{name: 'PacksPage'}" @click.native="showNavBar = false" active-class="is-active")
          b-icon.mr-1(icon="view-module")
          | {{ navCompactMode ? '' : 'Packages'}}

      li
        router-link(:to="{name: 'SharedLessonsPage'}" @click.native="showNavBar = false" active-class="is-active")
          b-icon.mr-1(icon="folder-shared")
          | {{ navCompactMode ? '' : 'Community lessons'}}

      li
        router-link(:to="{name: 'NewSmartLessonPage'}" @click.native="showNavBar = false" v-if="lessons.length > 5 && user.is_teacher" active-class="is-active")
          b-icon.mr-1(icon="view-compact")
          | {{ navCompactMode ? '' : 'Lesson Builder'}}

    //- Recent
    p.menu-label(v-if="lastLessons.length && !navCompactMode") Recent lessons
    ul.menu-list(v-if="lastLessons.length && !navCompactMode")
      li( v-for="lesson in lastLessons")
        router-link.is-size-7(
          :to="{name: 'LiveLesson', params:{ id: lesson.uuid}}"
          :key="lesson.id"
          @click.native="showNavBar = false"
          active-class="is-active"
        )
          b-icon(:icon="lesson.icon" v-if="lesson.icon" custom-size="mdi-18px")
          span
            | {{ lesson.title }}

    //- Booking
    //-p.menu-label Booking
    //-ul.menu-list
      li
        router-link(:to="{name: 'Bookings'}" @click.native="showNavBar = false" active-class="is-active")
          | Bookings
      li
        router-link(:to="{name: 'Availabilities'}" @click.native="showNavBar = false" v-if="user.is_teacher" active-class="is-active")
          | Availabilities

    //- Miscellaneous
    template(
      v-if="user.id && user.is_teacher && !navCompactMode"
    )
      p.menu-label Miscellaneous
      ul.menu-list
        li
          a#become_affiliate_navbar(
            href="/affiliate"
            target="_blank"
          )
            CashIcon.mr-1
            span Become an affiliate
          //-a(href="https://news.teachee.io" target="_blank" @click="showNavBar = false")
            | English resources

    //- Admin
    p.menu-label(v-if="user.id == 1 && !navCompactMode") Admin
    ul.menu-list(v-if="user.id == 1 && !navCompactMode")
      li
        router-link(:to="{name: 'UsersPage'}" @click.native="showNavBar = false" active-class="is-active")
          b-icon(icon="vpn-key")
          span Users
      li
        router-link(:to="{name: 'CorrectionsPage'}" @click.native="showNavBar = false" v-if="user.id == 1" active-class="is-active")
          b-icon(icon="merge-type")
          span Corrections

      li
        router-link(:to="{name: 'AdminAllSentencesPage'}" @click.native="showNavBar = false" v-if="user.id == 1" active-class="is-active")
          b-icon(icon="list")
          span Sentences
</template>

<script>
  import {mapGetters, mapMutations} from 'vuex'
  import { HomeIcon, ClipboardListIcon, UserGroupIcon, CashIcon } from "@vue-hero-icons/solid"


  export default {
    name: 'SidebarMenu',
    components: {
      HomeIcon,
      ClipboardListIcon,
      UserGroupIcon,
      CashIcon
    },
    data () {
      return {
        isCreating: false,
        showNavBar: false,
        lesson: {
          title: '',
          language_to: 'en-US'
        },
      }
    },
    computed: {
      ...mapGetters(['lastLessons', 'user', 'students', 'lessons', 'usedLanguages', 'isGuest', 'navCompactMode']),
    },
    mounted () {
      if (window.screen.availWidth < 768) {
        this.SET_NAV_COMPACT_MODE(true)
      }
    },
    methods: {
      ...mapMutations(["SET_NAV_COMPACT_MODE"])
    }
  }
</script>

<style lang="scss">
  .ui--sidebar-menu {
    text-align: left;
    min-width: 240px;
    transition: min-width .8s ease;
    a {
      display: flex !important;
      align-items: center;
      &.flag-link{
        display: inline-block !important;
      }
    }
    i.flag {
      transform: scale(0.8);
    }

    &.compact-mode {
      min-width: 50px;
      padding-right: 0 !important;
      a {
        display: inline-flex !important;
      }
      .menu-list li ul {
        margin-left: -0.55rem;
      }

      .btn-is-compacted {
        margin-left: 10px !important;
        position: relative;
      }
    }
  }
  @media screen and (max-width: 768px) {
    body {
      .ui--sidebar-menu {
        min-width: 100vw !important;
        display: flex;
        align-items: center;
        //background-color: #dedede !important;
        background-color: #d6d2e7 !important;
        position: fixed;
        -webkit-backface-visibility: hidden;
        padding: 0 !important;
        bottom: 0;
        left: 0;
        z-index: 30;
        ul {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          ul {
            display: none !important;
          }
        }
      }
    }
  }
</style>
